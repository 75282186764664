.container-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    height: 100vh;

    img {
        margin-top: 8rem;
        width: 19rem;
    }

    p {
        font-size: 45px;
        font-weight: bold;
        color: $primaryColor-Blue;

        @include for-tablet-portrait-up {
            font-size: 25px;

        }
    }

    div {
        padding-bottom: 10rem;
    }
}