.header-container {
    width: 100%;
    height: 82px;
    background-color: $primaryColor-Blue;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;

    &__logo {
        width: 15rem;

        @include for-tablet-portrait-up {
            width: 10rem;
        }
    }

    &__button-down {
        border: none;
        background-color: $primaryColor-Blue;
        height: 2rem;

        span {
            color: $white;
        }

        &:focus {
            transform: rotate(180deg);
            transition: all .5s;
        }
    }

    &__button {
        display: flex;
        gap: 15px;
        align-items: center;
        align-self: flex-start;

        .anticon-user {
            color: $white;
            transform: scale(1.8);
        }
    }

    &__button-menu {
        border: none;
        background-color: $primaryColor-Blue;

        .anticon-menu {
            color: $white;
            transform: scale(1.8);
        }
    }


    &__nombre {
        font-weight: bold;
        color: $white;
        font-size: 20px;
        margin: 0;
    }

}

.dropdown-content {
    display: block;

    div {
        position: absolute;
        right: 10px;
        top: 30px;
    }

}

.dropdown-content-list {
    color: $black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

@include for-phone-only {
    .header-container {
        width: 100%;
        height: 70px;
        background-color: $primaryColor-Blue;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        padding: 20px 15px;

        &__button {
            gap: 5px;
            align-self: self-end;
            width: 3rem;

            .anticon-user {
                color: $white;
                transform: scale(1.2);
            }
        }

        &__button-menu {
            border: none;
            background-color: $primaryColor-Blue;
            width: 3rem;

            span {
                color: $white;
            }

            .anticon-close {
                transform: scale(1.5);

            }
        }



        &__nombre {
            font-weight: bold;
            color: $white;
            font-size: 20px;
            margin: 0;
        }

    }



    .dropdown-content-list {
        color: $black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
}