.ant-breadcrumb{
    ol{
        li{
            .ant-breadcrumb-link{
                a{
                    color: $white;
                    font-size: 14px;
                    font-weight: bold;
                }
            }

        }
    }
    
    .ant-breadcrumb-separator{
            color: $white;
            font-size: 14px;
            font-weight: bold;
    }
}