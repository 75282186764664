.link {
    display: flex;
    gap: 30px;
    font-weight: bold;
    cursor: pointer !important;
}

.linkActive {
    display: flex;
    gap: 27px;
    font-weight: bold;
    cursor: pointer !important;
    background-color: $primaryColor-Blue;
    color: $white;
}

.ant-layout-sider-children,
.ant-menu,
.ant-menu-dark,
.ant-layout-sider {
    background-color: $accent-blue !important;

}


.ant-layout-sider-trigger {
    background-color: $primaryColor-Blue;
}

.ant-menu-item-selected {
    cursor: pointer !important;
    background-color: $primaryColor-Blue !important;
    color: $white;
}

@include for-phone-only {
    .ant-layout-sider-has-trigger {

        min-height: 100% !important;
        height: 100vh !important;

    }

    .ant-layout-sider-trigger {
        display: none;
    }
}

.sidebar {
    background-color: $accent-blue;


}