.ant-popover-buttons {
    .ant-btn-default {
        background-color: $accent-gray;
        color: $primaryColor-Blue;
        font-weight: bold;
        border-radius: 4px;
        border: none;
    }

    .ant-btn-primary {
        background-color: $primaryColor-Blue;
        color: $accent-gray;
        font-weight: bold;
        border: none;
        border-radius: 4px;
    }
}

.ant-popover-inner-content {
    background-color: $grey;
    color: $grey;
}

.ant-popover-arrow-content {
    display: none;

}
.ant-popover-message-title{
    color: $primaryColor-Blue;
    font-size: 14px;
    font-weight: bold;
}
.ant-popover-message{
    span{
        display: none;
    }
}
.ant-popover-arrow{
}
.ant-popover-inner{
    width: 22rem !important;
}