.react-datepicker__input-container {
    input{
        width: 100%;
        border: none;
        color: #000000;
        padding-left: 10px;
        font-size: 16px;
        background: #FFFFFF;
        border: 1px solid hsl(0, 0%, 85%);
        border-radius: 4px;
        
    }
}