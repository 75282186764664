.accordion-container {
    background-color: $primaryColor-Blue;
    margin-bottom: 25px;

    .ant-collapse-header {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        padding: 0 20px;
    }

    .ant-collapse-header-text {
        color: $white;
    }
}

.ant-collapse-content {
    background-color: $accent-gray;
    padding: -20px;
}

.ant-collapse-expand-icon {
    color: $white;
    transform: scale(1.8);
    font-weight: bold;
}

.accordion-container-box {
    border: 1px solid $white;
}

.accordion-box {
    background-color: $secondaryColor-gray;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@include for-tablet-portrait-up {
    .ant-collapse-content-box {
        padding: 5px !important;
    }
}

.ant-collapse-ghost {
    .ant-collapse-item {
        .ant-collapse-header {
            width: 15rem !important;
            margin: 2rem auto;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-evenly !important;

            .ant-collapse-expand-icon {
                color: $primaryColor-Blue;
            }

            .ant-collapse-header-text {
                color: $primaryColor-Blue;
                font-weight: bold;
            }
        }
    }
}