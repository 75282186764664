.info-container {
    margin: 20px;
    background-color: $white;
    padding: 20px;

    &__grafica {
        margin-top: 1rem;
    }
    &__grafica-acumulado {
        display: flex;
        justify-content: center;
    }

    // @include for-tablet-portrait-up {
    //     padding: 10px;
    //     margin: 5px;
    // }

    &__nothing {
        color: $primaryColor-Blue;
        text-align: center;
        font-size: 20px;
        font-weight: bold;

    }

    &__objetivo {
        text-align: right;
    }

    &__title {
        background-color: $primaryColor-Blue !important;
        padding: .5rem 1rem;
        border-radius: 4px;

        h2 {
            color: white;
            font-weight: bold;
            padding-top: .3rem;
        }
    }

    &__form {
        margin-top: 1rem;
        border: 1px solid $accent-blue;
        border-radius: 4px;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.217);
    }

    &__reporte {
        margin-top: 1rem;
        border: 1px solid $accent-blue;
        border-radius: 4px;
    }

    &__encabezado-mobile {
        display: flex;
        justify-content: space-between;
        margin: 1rem;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 3px solid $accent-blue;

        @include for-tablet-portrait-up {
            flex-direction: column;
            align-items: center;
        }
    }
    
    &__encabezado {
        display: flex;
        justify-content: space-between;
        margin: 1rem;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 3px solid $accent-blue;
    }

   

    &__card-reporte {
        display: flex;
        flex-direction: column;
        border: 1px solid $primaryColor-Blue;
        margin: .5rem 2rem;
        border-radius: 4px;
    }
    &__card-reportegeneral {
        display: flex;
        flex-direction: column;
        border: 1px solid $primaryColor-Blue;
        margin: .5rem 2rem;
        border-radius: 4px;
    }

    &__card-reporte--mobile {
        display: flex;
        flex-direction: column;
        border: 1px solid $primaryColor-Blue;
        margin: .5rem;
        border-radius: 4px;
        @include for-tablet-portrait-up {
            overflow-x: auto;
        }
    }

    &__title-reporte {
        background-color: $accent-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;

        p {
            color: white;
            font-weight: bold;
            font-size: 18px;
            padding-top: .8rem;
        }
    }

    &__separadores-reporte {
        display: flex;
    }

    &__separador-reporte-identificador {
        border: 1px solid $primaryColor-Blue;
        padding-block: .5rem;
        font-weight: bold;
        color: $accent-blue;
        width: 14.99rem;
        display: flex;
        justify-content: center;
    }

    &__separador-reporte-valor {
        border: 1px solid $primaryColor-Blue;
        padding-block: .5rem;
        font-weight: bold;
        color: $accent-blue;
        width: 54rem;
        display: flex;
        justify-content: center;
    }


    &__nombre-empresa {
        display: flex;
        justify-content: flex-end;
        margin-right: 1rem;

        p {
            color: $grey-Dark;
            font-weight: bold;
            font-size: 30px;
        }
    }

    &__nombre-empresa-mobile {
        display: flex;
        justify-content: flex-end;

        @include for-tablet-portrait-up {
            flex-direction: column;
            align-items: center;
        }
    }

    &__table-general {
        .ant-table-thead {
            .ant-table-cell {
                font-weight: bold;
                border: 1px solid $primaryColor-Blue;
            }
        }

        .ant-table-tbody {
            tr {
                .ant-table-cell {
                    border: 1px solid $primaryColor-Blue;
                    background-color: #FEE7E7;
                }

            }
        }
    }

    &__table-bloques {
        .ant-table-thead {
            .ant-table-cell {
                font-weight: bold;
                border: 1px solid $primaryColor-Blue;
            }
        }

        .ant-table-tbody {
            tr {
                .ant-table-cell {
                    border: 1px solid $primaryColor-Blue;
                }

            }
        }
    }
}

.not-sensores {
    background-color: $accent-gray;
    text-align: center;
    padding-block: 50px;

    p {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        color: $primaryColor-Blue;

        @include for-phone-only {
            font-size: 16px;
        }
    }
}

.text-sensor {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: $primaryColor-Blue;
    margin-bottom: 0;

}