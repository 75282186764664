.card-container {
    background-color: $accent-blue;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;
    border-bottom: 1px solid $white;
    margin-block: 10px;

    &__header {
        border-bottom: 1px $white solid;
        padding: 5px 50px;
        font-weight: bold;
        display: flex;
        justify-content: start;

        @include for-tablet-portrait-up {
            display: flex;
            padding-inline: 25px !important;
            align-items: center;
            flex-direction: row-reverse;
            height: 3rem;
            gap: 2rem;
        }

        p {
            color: $white  !important;
            margin-top: 10px;
            margin-left: -2rem;

            @include for-tablet-portrait-up {
                width: 10rem;
                margin-left: -.5rem;
                margin-top: .9rem;

            }
        }
    }


    &__header-radio {
        padding-top: 5px;
        padding-right: 28rem;

        @include for-tablet-portrait-up {
            padding-right: 45rem;

        }
    }

    &__body {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__info {
        margin-inline: 30px 20px;
    }
}