.graficas-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: $white;
    padding: 1rem .5rem;

    @include for-phone-only {
        padding: .5rem .2rem;
    }

    p {
        font-size: 20px;
        font-weight: bold;
        color: $primaryColor-Blue;
        text-align: center;

        @include for-phone-only {
            font-size: 15px;
        }
    }
}
 .ant-tabs-content-holder, .ant-tabs, .ant-tabs-top{
    background-color: $white !important;

}
.ant-tabs-nav-wrap{
    padding-inline: 1rem;
}