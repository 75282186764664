.modal-container {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;

    &__body {
        position: relative;
        width: 600px;
        height: 562px;
        background: $white;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 10px;
    }

    &__form {

        padding-top: 50px;
    }

    &__close {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        border: none;
        background: transparent;
        padding-right: 20px;
        padding-top: 10px;
    }

    &__box {
        display: flex;
        margin: auto;
        justify-content: center;
        padding-top: 20px;
    }

}

.ant-modal-body {
    padding: 6px;
}