.ant-pagination-item-link{
    border-radius: 50% !important;
    border: .2px solid $accent-gray;
}
.ant-pagination-item-active{
    background-color: $accent-blue;
    border-radius: 50%;
    border: none;
    a{
        color: $white !important;
    }
}

.ant-pagination-item{
    border-radius: 50%;
    border: .2px solid $accent-gray;

}