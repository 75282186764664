.card-grafica-container {
    display: flex;
    flex-direction: column;
    width: 55rem;
    margin: auto;
    border-radius: 4px;
    border: 1px solid $primaryColor-Blue;

    &__header {
        background-color: $primaryColor-Blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 2rem;
        width: 100%;
    }

    &__title {
        color: $white !important;
        font-size: 20px;
        font-weight: bold;
        padding-top: 10px;
    }

    &__body-grafica {
        margin: auto;
    }

    &__body-grafica-sankey {
        margin: 1rem auto;

        div {
            height: 900px !important;

            div div div svg {
                height: 900px !important;

            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        padding-bottom: .5rem;

    }


    &__sensor {
        display: flex;
        justify-content: space-between;
        padding-inline: 2rem;
        background-color: $primaryColor-Blue;
        color: $white;
        font-weight: bold;
        font-size: 18px;
        width: 90%;
        margin: 1rem auto;
        border-radius: 4px;
        align-items: center;

        p {
            padding-top: 15px;
        }
    }

    &__box-sensor {
        display: flex;
        flex-direction: column;
        margin-inline: 2rem;
    }

    &__tipo {
        background-color: $primaryColor-Blue;
        border-radius: 4px;
        font-weight: bold;
        font-size: 20px;
        text-align: center;

        p {
            color: $white !important;
            padding-top: 10px;
        }

    }

    &__box-info {
        display: flex;
        justify-content: space-between;
        margin-inline: 1rem;
        margin-top: 1rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 12rem;
        text-align: center;

        p {
            font-weight: bold;
            font-size: 20px;
        }
    }
}

.card-grafica-container-info {
    display: flex;
    flex-direction: column;
    width: 60rem;
    margin: auto;
    border-radius: 4px;
    border: 1px solid $primaryColor-Blue;

    &__header {
        background-color: $primaryColor-Blue;
        text-align: center;
        width: 100%;
    }

    &__title {
        color: $white !important;
        font-size: 20px;
        font-weight: bold;
        padding-top: 10px;
    }

    &__body-grafica {
        margin: auto;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        padding-bottom: .5rem;
    }


    &__sensor {
        display: flex;
        justify-content: space-between;
        padding-inline: 2rem;
        background-color: $primaryColor-Blue;
        color: $white;
        font-weight: bold;
        font-size: 18px;
        width: 90%;
        margin: 1rem auto;
        border-radius: 4px;
        align-items: center;

        p {
            padding-top: 15px;
        }
    }

    &__box-sensor {
        display: flex;
        flex-direction: column;
        margin-inline: 2rem;
    }

    &__tipo {
        background-color: $primaryColor-Blue;
        border-radius: 4px;
        font-weight: bold;
        font-size: 20px;
        text-align: center;

        p {
            color: $white !important;
            padding-top: 10px;
        }
    }

    &__box-info {
        display: flex;
        justify-content: space-between;
        margin-inline: 1rem;
        margin-top: 1rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 12rem;
        text-align: center;

        p {
            font-weight: bold;
            font-size: 20px;
        }
    }
}

@include for-tablet-portrait-up {
    .card-grafica-container {
        width: 23rem;

        &__title {
            font-size: 17px;
        }

        &__info {
            width: 8rem;

            p {
                font-size: 14px !important;
            }
        }

        &__tipo {
            p {
                font-size: 16px !important;
            }

        }
    }

    .card-grafica-container-info {
        width: 26rem;

        &__title {
            font-size: 17px;
        }

        &__info {
            width: 8rem;

            p {
                font-size: 14px !important;
            }
        }

        &__tipo {
            p {
                font-size: 16px !important;
            }

        }
    }
}


@include for-phone-only {
    .card-grafica-container {
        width: 19.5rem;

        &__title {
            font-size: 17px !important;
        }


        &__sensor {
            padding-inline: 2rem;
            font-size: 14px;
            margin: 1rem auto;

            p {
                padding-top: 7px;
            }
        }

        &__tipo {
            p {
                font-size: 12px !important;
            }
        }

        &__box-sensor {
            margin-inline: .5rem;
        }

        &__box-info {
            margin-inline: .2rem;
            margin-top: .5rem;
        }

        &__info {
            width: 5rem;

            p {
                font-size: 12px !important;
            }
        }
    }

    .card-grafica-container-info {
        width: 22rem;

        &__title {
            font-size: 17px !important;
        }

        &__sensor {
            padding-inline: 2rem;
            font-size: 14px;
            margin: 1rem auto;

            p {
                padding-top: 7px;
            }
        }

        &__tipo {
            p {
                font-size: 12px !important;
            }
        }

        &__box-sensor {
            margin-inline: .5rem;
        }

        &__box-info {
            margin-inline: .2rem;
            margin-top: .5rem;
        }

        &__info {
            width: 5rem;

            p {
                font-size: 12px !important;
            }
        }
    }

    .apexcharts-zoomout-icon,
    .apexcharts-zoomin-icon {
        display: none !important;
    }
}