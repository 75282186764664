.select-container {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 350px;

  @include for-tablet-portrait-up {
    width: 250px;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: $primaryColor-Blue;
    margin-bottom: 0;
  }

  &__option-default {
    color: $accent-gray  !important;
  }

  &__select {
    width: 100%;
    border: none;
    color: $black;
    padding: 4px 10px;
    font-size: 16px;
    background: $white;
    border: 1px solid $accent-gray;
    border-radius: 4px;

    &:disabled {
      background-color: $accent-gray;
      color: grey;
      cursor: not-allowed;
    }

    &:active {
      border-color: $primaryColor-Blue;
    }
  }
}