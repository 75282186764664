.page-container {
    &__box {
        background-color: $white;
        min-height: 100vh;
        height: 100%;
        padding: 20px;
        border-radius: 4px;

        @include for-tablet-portrait-up {
            padding: 5px;
        }
    }



    &__pagination {
        display: flex;
        justify-content: flex-end;
        padding-block: 10px;

    }
}

.reset-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin: 2rem 5rem;
    padding: 5rem 2rem;
    align-items: center;

    @include for-tablet-portrait-up {
        margin: 1rem 1rem;
        padding: 4rem 2rem;

    }

    h2 {
        font-weight: bold;
        color: $primaryColor-Blue;
        font-size: 30px;

        @include for-tablet-portrait-up {
            font-size: 20px;
        }
    }

    &__input {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
}
