.container-checkbox-sensor {
    display: flex;
    margin-top: 15px;
    margin-right: 40px;

    p {
        font-size: 14px;
        margin-left: .5rem;
    }

    input {
        width: 18px;
        height: 18px;
        accent-color: #1890ff;
    }
}

.orden-sensores {
    display: flex;
    flex-wrap: wrap;
    align-self: baseline;
}
.container-checkbox-tarjeta{
    h2{
        color: $grey-Dark;
        font-size: 18px;
    }
}

.container-checkbox-elemento {
    margin-bottom: 45px;

    h1 {
        font-size: 25px;
        font-weight: bold;
    }

    h2 {
        font-size: 22.5px;
        font-weight: bold;
    }
}
