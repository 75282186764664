@media print {
    @page {
        margin: .6cm;
    }

    @page :blank {
        background-color: red;
    }

    .info-container {
        &__reporte {
            page-break-after: auto;
            margin: 0 !important;
        }

        &__card-reporte {
            border: none;
        }

        &__card-reportegeneral {
            page-break-after: always;
            border: none;
            width: max-content;

        }

        &__card-reporte--mobile {
            border: none;
        }

        &__table-general,
        &__table-bloques {
            .ant-table-tbody {
                tr {
                    page-break-inside: avoid;
                }
            }
        }

        &__card-bloque {
            page-break-before: auto;
        }

        &__bloque-bloques {
            page-break-inside: auto;
        }

        &__reporte:last-child {
            border: none;
            page-break-after: auto;
        }

        &__nombre-empresa {
            align-items: flex-end;
            justify-content: flex-end;
        }

        &__encabezado {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .logo {
        width: 15rem;
        margin-right: auto;
    }

    .empresa {
        width: 10rem;
    }

    .exclude {
        display: none !important;
    }
}