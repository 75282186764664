.home-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem !important;
    width: 95%;
    margin: auto;
    border: 1px solid $primaryColor-Blue;
    border-radius: 4px;
    background-color: $white;


    &__header {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: $primaryColor-Blue;
        color: $white;
        width: 100%;
        font-size: 15px;
        font-weight: bold;
    }

    &__body {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        padding-inline: 1rem;

        @include for-tablet-portrait-up {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }


        img {
            padding-block: 1rem;
            width: 50rem;
            border-radius: 4px !important;

            @include for-phone-only {
                border-radius: 4px !important;
                width: 15rem;
            }
        }
    }

    &__logo {
        padding-block: 5rem;
        display: flex;
        justify-content: center;
        img{
            width: 25rem;
            @include for-phone-only {
                width: 15rem;
            }
        }
    }

    &__empresa {
        display: flex;
        flex-direction: column;
        color: $grey-Dark;
        font-weight: bold;
        font-size: 30px;
        align-items: end;
        background-color: $white;
        width: 100%;

        @include for-tablet-portrait-up {
            align-items: center;
            font-size: 18px;
        }
    }

    &__footer {
        font-weight: bold;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: $primaryColor-Blue;
        color: $white;
        width: 100%;

        @include for-tablet-portrait-up {
            display: flex;
            flex-direction: column;
            text-align: start;
        }
    }
}