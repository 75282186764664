.form-info-container {
  padding: 20px;
  display: flex;
  flex-direction: column;

  @include for-tablet-portrait-up {
    padding: 10px;
  }

  &__clip {
    display: flex !important;
    align-items: flex-end;
    gap: .5rem;

    button {
      background-color: transparent;
      border: none;
      border-radius: 4px;

      svg {
        transform: scale(1.5);
      }
    }
  }

  &__pass {
    display: flex !important;
    align-items: center;
    gap: .5rem;

    button {
      background-color: transparent;
      border: none;
      padding-top: .6rem;
      border-radius: 4px;

      svg {
        transform: scale(1.5);
      }
    }
  }

  .button-selec {
    display: flex;
    align-self: flex-end;
    margin-top: 20px;
  }

  &__box-container {
    display: flex;
    gap: 200px;
    margin: auto;

    @include for-tablet-portrait-up {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }

  &__box-container-up {
    display: flex;
    flex-direction: column;
    margin: auto;

    @include for-tablet-portrait-up {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }

  &__box-container-reporte {
    display: flex;
    flex-direction: column;
    margin-top: -2rem;

    @include for-tablet-portrait-up {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }

  .form-objetivo {
    display: grid;
    gap: 2rem;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;

    @include for-tablet-portrait-up {
      align-items: center;
      padding-bottom: 15px;

    }
  }

  &__box-grid {
    margin-top: 20px;
    display: grid;
    grid-gap: 20px 80px;
    margin-inline: auto;
    grid-template-columns: repeat(2, 1fr);

    @include for-tablet-portrait-up {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__title {
    color: $primaryColor-Blue;
    font-size: 36px;
  }

  &__subtitle {
    color: $secondaryColor-gray;
    font-size: 16px;
  }

  &__button {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;
  }

  &__button--center {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }

  &__button-edit {
    display: flex;
    justify-content: flex-end;
  }

  &__accordion {
    margin-top: 30px;
  }

  &__accordion-sensores {
    margin-top: 30px;
    height: 25rem;
    overflow-y: auto;
  }
}

#image {
  display: none;
}

.custom-file-input {
  display: inline-block;
  background-color: #263047;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  width: 50%;
  cursor: pointer;
}

.hidden-file-input {
  display: none;
}