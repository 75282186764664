.input-container {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-block: .3rem;

  @include for-tablet-portrait-up {
    width: 250px;
  }


  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: $primaryColor-Blue;
    margin-bottom: 0;
  }

  &__input {
    width: 100%;
    border: none;
    color: $black;
    padding-left: 10px;
    font-size: 16px;
    background: $white;
    border: 1px solid $accent-gray;
    border-radius: 4px;

    &:disabled {
      background-color: $accent-gray;
      color: grey;
      cursor: not-allowed;
    }

    &:active {
      border-color: $primaryColor-Blue;
    }
  }
}

.input-container-checkbox {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-block: .3rem;

  @include for-tablet-portrait-up {
    width: 250px;
  }


  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: $primaryColor-Blue;
    margin-bottom: 0;
  }

  &__input {
    width: fit-content;
    border: none;
    color: $black;
    padding-left: 10px;
    font-size: 16px;
    background: $white;
    border: 1px solid $accent-gray;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;

    &:disabled {
      background-color: $accent-gray;
      color: grey;
      cursor: not-allowed;
    }

    &:active {
      border-color: $primaryColor-Blue;
    }
  }
}

.input-container--login {
  border: 1px solid $secondaryColor-gray;
  border-radius: 4px;
  display: flex;
  margin-block: 5px;
  width: 300px;

  @include for-tablet-portrait-up {
    width: 220px;
  }

  &__box {
    width: 47px;
    height: 40px;
    background-color: $accent-gray;

    @include for-tablet-portrait-up {
      height: 35px;
      width: 30px;
    }
  }

  &__input {
    width: 253px;
    border: none;
    color: $black;
    padding-left: 10px;
    font-size: 16px;

    @include for-tablet-portrait-up {
      width: 190px;
      font-size: 12px;
      padding-left: 5px;
    }

    &:active {
      border-color: $primaryColor-Blue;
    }
  }
}

.input-cont {
  display: flex;
  flex-direction: column;
  margin-block: 20px;

  @include for-tablet-portrait-up {
    margin-block: 10px;
  }
}

.card-container__radio {

  .ant-radio .ant-radio-inner {
    border-color: $white !important;
    background-color: $accent-blue;
    width: 30px;
    height: 30px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $white !important;
    background-color: $white;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: $white;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $accent-blue ;
  }
}

.react-datepicker__input-container input:disabled {
  background-color: $accent-gray;
  color: grey;
  cursor: not-allowed;
}