.main-container {
    background-color: $accent-gray;
    display: flex;

    &__page {
        background-color: $accent-gray;
        flex: 1;
        overflow-x: auto;


        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.sidebar {
    position: relative;

    aside {
        min-height: 100vh;
        height: 100%;
    }
}

.header-nav {
    background-color: $accent-blue;
    padding-block: 10px;
    display: flex;
    padding-inline: 20px;
    align-items: center;
    justify-content: space-between;
}

.ant-layout-content {
    background-color: $accent-gray;
}

.menu-phone {
    position: absolute;
    z-index: 100;
    border-right: 2px solid $accent-gray;
    min-height: 100vh;
    height: 100%;
    transition: all ease .2s !important;
    -webkit-transition: 2s ease-in-out !important;
}