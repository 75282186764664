.button-container {
    &__button--primary {
        background-color: $primaryColor-Blue;
        color: $white;
        width: 230px;
        font-weight: bold;
        padding-block: 10px;
        border-radius: 4px;
        font-size: 20px;
        transition: .5s;

        &:disabled {
            background-color: $accent-gray;
            color: grey;
            cursor: not-allowed;
            &:hover {
                background-color: $accent-gray;
                color: grey;
                cursor: auto;
            }
        }

        &:hover {
            background-color: $accent-blue;
            color: $white;
        }

        &:active {
            background-color: $primaryColor-Blue;
            color: $white;
        }

        @include for-tablet-portrait-up {
            width: 150px;
            height: 30px;
            padding: 0;
        }

        @include for-phone-only {
            width: 180px;
            height: 40px;
            font-size: 14px;
        }
    }

    &__button--gray {
        background-color: $accent-gray;
        color: $primaryColor-Blue;
        border: 1px solid $accent-gray;
        font-size: 16px;
        font-weight: bold;
        border-color: transparent;
        margin: 5px;
        min-width: 120px;
        transition: .5s;

        &:hover {
            background-color: $accent-blue;
            color: $white;
            border: 1px solid $accent-gray;
        }

        &:active {
            background-color: $primaryColor-Blue;
            color: $white;
        }

        @include for-phone-only {
            font-size: 14px;
            min-width: 80px;
        }

        &:disabled {
            background-color: $accent-gray;
            color: grey;
            cursor: not-allowed;

        }
    }

    &__button--redd {
        background-color: $delete-red;
        color: $accent-gray;
        border: 1px solid $delete-red;
        font-size: 16px;
        font-weight: bold;
        border-color: transparent;
        margin: 5px;
        min-width: 120px;
        transition: .5s;

        &:hover {
            background-color: $delete-red;
            color: $white;
            border: 1px solid $accent-gray;
        }

        &:active {
            background-color: $primaryColor-Blue;
            color: $white;
        }

        @include for-phone-only {
            font-size: 14px;
            min-width: 80px;
        }

        &:disabled {
            background-color: $accent-gray;
            color: grey;
            cursor: not-allowed;

        }
    }

    &__button--redd {
        background-color: $delete-red;
        color: $accent-gray;
        border: 1px solid $delete-red;
        font-size: 16px;
        font-weight: bold;
        border-color: transparent;
        margin: 5px;
        min-width: 120px;
        transition: .5s;

        &:hover {
            background-color: $delete-red;
            color: $white;
            border: 1px solid $accent-gray;
        }

        &:active {
            background-color: $primaryColor-Blue;
            color: $white;
        }

        @include for-phone-only {
            font-size: 14px;
            min-width: 80px;
        }

        &:disabled {
            background-color: $accent-gray;
            color: grey;
            cursor: auto;

        }
    }

    &__button--redd {
        background-color: $delete-red;
        color: $accent-gray;
        border: 1px solid $delete-red;
        font-size: 16px;
        font-weight: bold;
        border-color: transparent;
        margin: 5px;
        min-width: 120px;
        transition: .5s;

        &:hover {
            background-color: $delete-red;
            color: $white;
            border: 1px solid $accent-gray;
        }

        &:active {
            background-color: $primaryColor-Blue;
            color: $white;
        }

        @include for-phone-only {
            font-size: 14px;
            min-width: 80px;
        }

        &:disabled {
            background-color: $accent-gray;
            color: grey;
            cursor: auto;

        }
    }

    &__button--red {
        position: absolute;
        background-color: $delete-red;
        padding: 5px;
        z-index: 1;
        font-weight: bold;
        font-size: 16px;
        color: $white;
        text-decoration: none;
        border: transparent;
        display: none;
        right: 0;
        transition: .5s;

        &:hover {
            background-color: $accent-gray;
            color: $primaryColor-Blue;
        }
    }

    &__button--white {
        background-color: white;
        color: $primaryColor-Blue;
        border: 1px solid $accent-gray;
        font-size: 16px;
        font-weight: bold;
        border-color: transparent;
        margin: 5px;
        min-width: 120px;
        transition: .5s;

        &:hover {
            background-color: $accent-blue;
            color: $white;
            border: 1px solid $accent-gray;
        }

        &:active {
            background-color: $primaryColor-Blue;
            color: $white;
        }

        @include for-phone-only {
            font-size: 14px;
            min-width: 80px;
        }

        &:disabled {
            background-color: $accent-gray;
            color: grey;
            cursor: not-allowed;

        }
    }


}