//General colors
$secondaryColor-gray: #B4B4B4;
$primaryColor-Blue: #263047;
$accent-gray: #D9D9D9;
$white: #FFFFFF;
$black: #000000;
$grey: #EBEDEF;
$grey-Dark: #767676;

//Sidebar
$accent-blue: #303C54;

//User card
$user-card: #4D2C91;

//delete
$delete-red: #FB3232;

//Enterprise
$enterprise-headerCard-blue: #26418F;