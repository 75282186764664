.error-container {
    position: relative;
    width: 100%;
    margin-top: -8px;
    &__error {
        position: absolute;
        color: red !important;
        font-weight: lighter !important;
    }
}
@include for-tablet-portrait-up {
    .error-container {
        position: relative;
        width: 100%;
        margin-top: -9px;
        &__error {
            font-size: 12px !important;
            position: absolute;
            color: red !important;
            font-weight: lighter !important;
        }
    }
}