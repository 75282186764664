.card-container__table {

    .ant-table {
        background-color: $accent-blue;
    }

    .ant-table-thead {
        .ant-table-cell {
            background-color: $accent-blue  !important;
            color: $white  !important;
            padding: 10px;
            font-weight: bold;
            border: none;

            @include for-tablet-portrait-up {
                min-width: 10rem;
            }
        }
    }

    .ant-table-tbody {
        tr {
            .ant-table-cell {
                background-color: $accent-blue  !important;
                padding: 10px;
                color: $white  !important;
                border: none;

                @include for-tablet-portrait-up {
                    max-width: 8rem;
                }
            }

        }
    }

}
.anticon-info-circle {
    color: $white;
    transform: scale(1.8);
}