*{
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    
}
body{
    background-color: $accent-gray;
}
li {
    list-style: none;
    cursor: pointer;
}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}
.text-nothing{

    font-size: 20px;
    font-weight: bold;
    color: rgb(87, 87, 87);
    display: flex;
    justify-content: center;
    @include for-tablet-portrait-up {
        flex-direction: column;
        font-size: 100%;

      }
}
.text-reporte-1{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: $primaryColor-Blue;
}
.text-reporte-2{
    font-size: 20px;
    text-align: center;
    color: rgb(87, 87, 87);
}
.text-nothing-text{
    font-size: 20px;
    font-weight: bold;
    color: $primaryColor-Blue;
}

.container-spin {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 2rem;
    div{
    }
}
.ant-spin-text{
    font-size: 20px !important;
    margin-top: 2rem;
    font-weight: bold;
    color: $primaryColor-Blue;
}
.ant-spin-dot-item{
    background-color: $primaryColor-Blue !important;
    transform: scale(2);

}
.ant-spin-dot {
    width: 2rem;
    height: 2rem;
}