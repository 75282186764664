.login-container {
    background-color: $accent-gray;
    position: relative;
    height: 100%;

    &__box {
        padding-block: 10rem 13.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__logo-container {
        background-color: $primaryColor-Blue;
        width: 29rem;
        height: 29rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 381px;

    }

    &__form-container {
        background-color: $white;
        width: 29rem;
        height: 29rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.form-login-container {
    padding: 100px;

    &__title {
        color: $primaryColor-Blue;
        font-size: 36px;
    }

    &__subtitle {
        color: $secondaryColor-gray;
        font-size: 16px;
    }

    &__button {
        margin-top: 50px;
    }
}

@include for-tablet-portrait-up {
    .login-container {
        background-color: $grey;
        position: relative;
        height: 100%;

        &__box {
            padding-block: 280px;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        &__logo-container {
            background-color: $primaryColor-Blue;
            width: 17rem;
            height: 18rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 170px;

        }

        &__form-container {
            background-color: $white;
            width: 17rem;
            height: 18rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .form-login-container {
        padding: 45px 60px;

        &__title {
            color: $primaryColor-Blue;
            font-size: 25px;
        }

        &__subtitle {
            color: $secondaryColor-gray;
            font-size: 12px;
        }

        &__button {
            margin-top: 20px;
        }
    }
}

@include for-phone-only {
    .login-container {
        background-color: $grey;
        position: relative;
        height: 100%;

        &__box {
            padding-block: 8rem 18rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }

        &__logo-container {
            display: none;
        }

        &__form-container {
            background-color: $white;
            width: 20rem;
            height: 22rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .form-login-container {
        padding: 45px;

        &__title {
            color: $primaryColor-Blue;
            font-size: 30px;
        }

        &__subtitle {
            color: $secondaryColor-gray;
            font-size: 18px;
        }

        &__button {
            margin-top: 20px;
        }
    }
}